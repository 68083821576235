
#home{
    margin-top: 100px;
    margin-bottom: 50px;
    @media (min-width: 576px){
        margin-top: 150px;
        margin-bottom: 100px;
    }
}

.home-page-title{
    position: relative;
    margin-bottom: 16px;
    &:after{
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 100%;
        height: 4.5px;
        transform: translateX(-50%);
        background-color: #898add;
        border-radius: 20px;
        @media screen and (max-width: 576px){
            width: 85%;
            bottom: -10px;
        }
    }
    @media screen and (max-width: 576px){
        margin-bottom: 24px;
    }
}

.brand_model{
    @media (max-width: 576px){
        text-align: center;
    }
}

.caroserie-svg svg {
    width: 75px; 
    @media (max-width: 768px) {
        width: 55px;  /* Adjust width on mobile */
    }
}

.MuiFormControl-root {
    .MuiInputBase-root{
        border-radius: 15px;
    }
}

.container-fluid {
    padding: 20px;
}

.card-section {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap; /* Ensures cards wrap on smaller screens */
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 24px 32px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%; /* Adjust for responsiveness */
    max-width: 350px; /* Ensures cards have a minimum size */
    min-height: 330px;
    @media (max-width: 576px) {
        min-height: 250px;
        padding: 32px;
    }
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.card h3 {
    margin-bottom: 10px;
    color: #333;
}

.card p {
    color: #555;
}

button {
    &.MuiButtonBase-root{
        border-radius: 18px;
    }
    &.Mui-disabled{
        color: rgb(255 255 255 / 60%);
    }

    &.MuiButton-containedPrimary{
        background-color: rgb(114 115 253) !important;
    }
    &.MuiButton-outlinedPrimary{
        border-color: rgb(114 115 253) !important;
        color: rgb(114 115 253) !important;
    }
}


.MuiSlider-root{
    height: 4px; // Make the slider track thinner
    color: #007bff; // Blue color for the track and thumb
    & .MuiSlider-track {
        border: none; // Remove border from track
    };
    & .MuiSlider-rail {
        opacity: 0.3; // Make the rail a lighter shade
        background-color: #ccc; // Lighter color for the rail
    };
    & .MuiSlider-thumb {
        height: 18px; // Make the thumb smaller
        width: 18px; // Make the thumb smaller
        background-color: #007bff; // Color of the thumb
        border: 2px solid #ffffff; // Add a white border around the thumb for contrast
        &:focus, &:hover, &:active {
            box-shadow: 0px 0px 0px 8px rgba(0, 123, 255, 0.16); // Add a subtle shadow on hover/focus
        };
    };
    & .MuiSlider-valueLabel {
        display: block;
        font-size: 12px; // Smaller font size for the value label
        top: -5px !important; // Position the value label slightly above the slider
        background-color: transparent; // Make the label background transparent
        color: #007bff; // Set the color of the label to blue
    }
}


.MuiSlider-track{
    background-color: rgb(114 115 253) !important;
}
.MuiSlider-root .MuiSlider-thumb{
    background-color: rgb(114 115 253) !important;
}

.MuiSlider-root .MuiSlider-valueLabel{
    color: rgb(114 115 253) !important;
    top: -10px;
}

.MuiCheckbox-colorPrimary{
    color: #2f364c !important;
}

.MuiChip-filled {
    background-color: rgb(114 115 253) !important;
}

.MuiFormLabel-colorPrimary {
    &.MuiFormLabel-filled {
        &.Mui-focused {
            color: rgb(114 115 253) !important;
        }
    }
}

.MuiOutlinedInput-notchedOutline{
    border-color: rgb(114 115 253) !important;
}

.MuiInputLabel-outlined{
    &.Mui-focused{
        color: rgb(114 115 253) !important;
    }

}