.numericSectionLink{
    color: white;
    cursor: pointer;
}

.numericSectionRow{
    gap: 32px 0;
    @media (min-width: 768px){
        gap: 32px;
    }
    .fontNumber{
        font-size: 58px;
        @media (min-width: 768px) {
            font-size: 68px;
        }
    }
}