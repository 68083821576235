.gosen-dropdown{
  display: flex;
  width: 150%;
  flex-direction: column;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  position: absolute;
  background-color: white;
  border-radius: 6px;
  bottom: 0;
  transform: translateY(100%);
  padding: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;

  a {
    padding: 4px 6px 4px 6px;
    &:hover{
      background-color: #f9f9f9c4;
    }
  }

  &.gosen_dropdown_active {
    opacity: 1;
    z-index: 1;
    visibility: hidden;
  }
}

.language-dropdown{
  width: 100%;

  div{
    padding: 4px 6px 4px 6px;
    &:hover {
      color: rgb(114 115 253);
      background-color: rgba(249, 249, 249, 0.768627451);
    }
  }
}

.changeLanguageMobile{
  display: none !important;
  margin-left: auto;
  @media (max-width: 760px) {
    display: block !important;
  }
}