.timeLineBox{
  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
}
.timeLineTabs{
  @media (max-width: 576px) {
    width: 100%;
  }
}

.timeLineContent{
  @media (max-width: 576px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}