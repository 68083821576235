.MuiAccordion-root {
  background: transparent !important;
  border: none !important;

  .MuiAccordionSummary-root{
    background: transparent !important;
    padding: 10px 15px !important;

    .MuiAccordionSummary-content{
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  .MuiAccordionDetails-root{
    color: white !important;
    padding: 0px;
    border-top-color: white !important;
  }
  .MuiAccordionSummary-expandIconWrapper{
    color: white !important;
  }
}