.headerImage{
  width: 470px;
  max-width: 100%;
}

.gosenHomeHeader{
  width: 100vw;
  height: 677px;
  margin-top: 80px;

  @media (max-width: 768px){
    height: 500px;

    h1{
      font-size: 42px;
      margin-bottom: 4px;
    }
  }

  @media (max-width: 550px){
    height: 460px;
  }


  .bgVideoContainer{
    position: absolute;
    width: 100vw;
    height: 100%;
    overflow: hidden;

    @media (max-width: 768px){
      height: 100%;
    }

    @media (max-height: 600px){
      height: 100%;
    }

    .bgVideo{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -2;
      transform: translate(-50%, -50%);

      width: 100vw;
      height: 100vh;

      @media (min-aspect-ratio: 16/9) {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
      }

      @media (max-aspect-ratio: 16/9) {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
      }
    }

    .bgCoverColor{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #1d1e20;
      opacity: 0.60;
    }
  }

  .mobileVideo{
    iframe{
      border-radius: 14px;
    }
  }

  #home{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -53%);
    width: 100vw;
    height: 100%;
    @media (min-width: 576px) {
      transform: translate(-50%, calc(-50% - 80px));
    }
  }
}

.subTitleHeader{
  font-size: 18px;
  margin-bottom: 12px !important;

  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
}

.subTitleItalicHeader{
  font-size: 16px;
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
}