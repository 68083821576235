/*testimonial*/
.slick-arrow{
  @media screen and (max-width: 1223px) {
    display: none !important;
  }
  &:before{
    color: rgb(47 53 75);
    font-size: 34px;
  }
}

.slick-prev{
  left: -31px;
}
.slick-next{
  right: -18px;
}